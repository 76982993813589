<script>
import { defineElement } from '@vueform/vueform/src/core.js';

export default defineElement({
  name: 'VueformRadioElement',
  setup(props, { element }) {
    const { update } = element;

    if (props?.defaultValue) {
      update(props?.defaultValue);
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: [],
    },
    headerLabel: {
      type: String,
      required: true,
      default: '',
    },
    defaultValue: {
      type: String,
      required: false,
      default: '',
    },
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <div class="w-full">
        <p
          class="text-left mt-0 mb-0.5 font-bold text-sm text-dark-700 dark:text-white"
        >
          {{ headerLabel }}
        </p>
        <div class="flex flex-row justify-content-start">
          <div
            v-for="(item, index) in items"
            :key="item.value"
            class="flex items-center mr-3 text-dark-700 dark:text-white"
          >
            <RadioButton
              v-model="value"
              :inputId="String(item.value)"
              name="dynamic"
              :value="item.value"
              class=""
              :pt="{
                input: ({ props }) => ({
                  class:
                    props.modelValue === item.value
                      ? 'blue-gradient border-none'
                      : 'dark:bg-dark-800 dark:b-dark-500 bg-light-50 b-light-400',
                }),
                icon: ({ props }) => ({
                  class:
                    props.modelValue === item.value
                      ? 'dark:bg-dark-800 bg-light-50'
                      : '',
                }),
              }"
            />
            <label :for="item.value" class="ml-2 py-1 capitalize">
              {{ item.label }}
            </label>
          </div>
        </div>
      </div>
    </template>
  </ElementLayout>
</template>

<style lang="scss">
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none;
}
</style>
