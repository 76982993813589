<script>
import { defineElement } from '@vueform/vueform/src/core.js';
import { datePickerPassthrough } from '~/components/passthroughs/datepicker';

export default defineElement({
  name: 'VueformCalendarElement',
  setup(props, { element }) {
    return { datePickerPassthrough };
  },
  props: {
    disableCal: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxCalDate: {
      type: Date,
      required: false,
      default: new Date('02-02-06'),
    },
    minCalDate: {
      type: Date,
      required: false,
      default: new Date('01-01-1924'),
    },
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <div class="relative mb-1">
        <label class="vf-cal-label text-black dark:text-white w-fit z-5">
          {{ $t(`label.dateOfBirth`) }}
        </label>
        <DatePicker
          id="calendar"
          v-model="value"
          :max-date="maxCalDate"
          :min-date="minCalDate"
          date-format="dd-mm-yy"
          :show-icon="true"
          :placeholder="$t('placeholder.dateOfBirth')"
          :disabled="disableCal"
          :pt="datePickerPassthrough"
        >
          <template #inputicon="slotProps">
            <div @click="slotProps.clickCallback">
              <slot name="icon" />
            </div>
          </template>
        </DatePicker>
      </div>
    </template>
  </ElementLayout>
</template>

<style scoped>
.vf-cal-label {
  font-size: 12px;
}
</style>
<style lang="scss">
.p-calendar input.p-component::placeholder {
  font-size: 16px;
}

.vf-element-layout div.relative button.p-datepicker-trigger {
  right: 0px;
}

.vf-element-layout div.relative .p-datepicker {
  top: 42px !important;
  left: -15px !important;
}
</style>
