<script setup lang="ts">
import Password from 'primevue/password';
import type { InputProps } from '~/interfaces/form/textInputInterface';
import {
  CheckCircleIcon,
  ChevronUpIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/solid';
import ExclamationIcon from '../icons/ExclamationIcon.vue';
import CircleIcon from '../icons/CircleIcon.vue';
import { passwordPassThrough } from '~/components/passthroughs/password';

const { $t } = useNuxtApp();

const props = withDefaults(defineProps<InputProps>(), {
  class: '',
  formKey: '',
  label: 'password',
  modelValue: '',
  feedback: true,
  labelClass: '',
  placeholder: 'placeholder.password',
});

const modelValue: Ref<string> = props.modelValue
  ? ref(props.modelValue)
  : ref('');
const emit = defineEmits(['update:modelValue', 'blur']);

function showValue(model: any) {
  const passwordChecker = document.getElementsByClassName(
    'p-hidden-accessible'
  )[1];
  const passwordCheckerValue = passwordChecker.innerHTML;

  const parentElement = passwordChecker.parentElement;

  if (passwordCheckerValue.trim() == 'Strong') {
    parentElement?.setAttribute(
      'class',
      'p-password p-component p-inputwrapper p-inputwrapper-filled p-input-icon-right'
    );

    const passwordField = document.getElementById('password');
    passwordField?.setAttribute('aria-expanded', 'false');
  }
}

const input: Ref<typeof Password> = ref();
const showChevron = ref(false);
const successGreen = 'primary-blue-500';

const passwordSuggestions = reactive([
  {
    text: $t('at-least-one-lower'),
    display: false,
    regex: ['([a-z])'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('at-least-one-upper'),
    display: false,
    regex: ['([A-Z])'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('at-least-one-num'),
    display: false,
    regex: ['([0-9])'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('min-eight-char'),
    display: false,
    regex: ['^(.){8,}$'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('at-least-one-special-char'),
    display: false,
    regex: ['[^\\w\\s]+|_+'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('please-remove-spaces'),
    display: false,
    regex: ['(\\s)'],
    icon: XCircleIcon,
    colour: 'red-500',
  },
  {
    text: $t('password-is-vulnerable'),
    display: false,
    regex: ['(?=.*password)', 'i'],
    icon: ExclamationIcon,
    colour: 'gold-500',
  },
]);

function passwordRegex() {
  passwordSuggestions.forEach((ps) => {
    const regex = new RegExp(ps.regex[0], ps?.regex[1] ? ps.regex[1] : ''); // regex modifier
    const isValid = regex.test(String(modelValue.value));
    ps.display = isValid;
  });

  // display close suggestions chevron
  setTimeout(() => {
    showChevron.value =
      input?.value?.$params?.state?.meter?.strength === 'strong';
  }, 250);
}

//set focus to password component when toggling mask
function togglePassword() {
  // setTimeout(() => {
  //   const input = document.getElementsByClassName('p-password-input');
  //   input[0].focus();
  // }, 250);
}
function closePopUp() {}
</script>

<template>
  <span class="flex relative">
    <Password
      :id="props.label"
      ref="input"
      v-model="modelValue"
      :feedback="props.feedback"
      :placeholder="$t(props.placeholder)"
      :input-props="{ autocomplete: 'off' }"
      :input-id="props.formKey"
      toggle-mask
      required
      size="large"
      panel-class="dark:bg-dark-800 bg-light-200 b-1 dark:b-dark-500 b-light-400 border-round-md p-0"
      prompt-label=" "
      medium-regex="^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[\d|\W])(?:.{8,20})$"
      strong-regex="^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?:.{8,20})$"
      fluid
      :pt="passwordPassThrough"
      @input="
        emit('update:modelValue', $event.target.value);
        showValue($event.target.value);
      "
      @blur="emit('blur')"
      @change="passwordRegex()"
    >
      <template #unmaskicon="{ toggleCallback }">
        <EyeIcon
          class="text-dark-600 dark:text-white mr-1 w-6 right-1 absolute top-[12px]"
          @click="toggleCallback"
        />
      </template>
      <template #maskicon="{ toggleCallback }">
        <EyeSlashIcon
          class="text-dark-600 dark:text-white mr-1 w-6 right-1 absolute top-[12px]"
          @click="toggleCallback"
        />
      </template>
      <template #header>
        <p
          class="my-0 py-2 pl-2 font-bold text-sm capitalize dark:text-white text-dark-700"
        >
          {{ $t('password-validity') }}
        </p>
        <ChevronUpIcon class="w-4 text-sm close-popup" @click="closePopUp()" />
      </template>
      <template #footer>
        <hr class="pl-0 w-full std-border" />
        <div class="pl-2">
          <p
            class="mt-2 mb-1 text-sm font-bold capitalize dark:text-white text-dark-700"
          >
            {{ $t('required') }}
          </p>
          <ul
            class="pl-0 ml-0 mt-0 list-none dark:text-white text-dark-700 mb-2"
            style="line-height: 1.5"
          >
            <div
              v-for="(ps, index) in passwordSuggestions"
              :key="index"
              class="flex align-content-center flex-wrap"
            >
              <component
                :is="ps.icon"
                v-if="ps.display"
                :key="index"
                class="mr-2 w-5"
                :class="`text-${ps.colour}`"
                :tooltip="
                  toRaw(ps.icon) === ExclamationIcon
                    ? 'password-contains-password'
                    : ''
                "
              />
              <CircleIcon
                v-else-if="ps.icon === CheckCircleIcon"
                class="mr-2 w-5"
              />
              <li
                v-if="ps.display || ps.icon === CheckCircleIcon"
                class="text-sm text-dark-700 dark:text-white"
              >
                {{ ps.text }}
              </li>
              <!-- component and colour depending on suggestion/warning/error -->
            </div>
          </ul>
        </div>
      </template>
    </Password>
    <label
      v-if="modelValue?.length > 0"
      class="password-float-label text-base-priority capitalize"
      :class="props.labelClass"
      :for="props.label"
    >
      {{ $t(props.label) }}
    </label>
  </span>
</template>
<style lang="scss">
.p-password.p-component {
  width: 100%;
}

.password-float-label {
  position: absolute;
  z-index: 99;
  font-size: 12px;
  top: 0px;
  left: 18px;
}

.p-password-meter {
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 8px;
  background-color: #dae0ed;
  height: 6px;
}
.p-password-info {
  display: none;
}

div.p-password-meter {
  border-radius: 8px;
}

.p-password-strength {
  &.weak,
  &.medium,
  &.strong {
    border-radius: 8px;
  }
}

.dark {
  .p-float-label .p-inputwrapper-filled ~ label.vueform-float-label {
    background-color: #1d2129;
  }

  .p-password-meter {
    background-color: #121417;
  }
  div.p-password svg.p-icon {
    color: #fff;
  }
}
div.p-password svg.p-icon {
  color: #2b303b;
}

.hide-password-popup {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    display: none;
  }
}

.show-password-popup {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    display: block;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hidden-password-styling {
  padding-left: 12px;
  letter-spacing: 2px !important;
}

input.input--password-field::placeholder {
  font-size: 16px;
  letter-spacing: 1px;
}
</style>
<style scoped>
.close-popup {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #fff;
  cursor: pointer;
}
</style>
