import Vueform from '@vueform/vueform/themes/blank/templates/Vueform.vue';
import FormErrors from '@vueform/vueform/themes/blank/templates/FormErrors.vue';
import FormMessages from '@vueform/vueform/themes/blank/templates/FormMessages.vue';
import FormSteps from '@vueform/vueform/themes/blank/templates/FormSteps.vue';
import FormStepsControls from '@vueform/vueform/themes/blank/templates/FormStepsControls.vue';
import FormStepsControl from '@vueform/vueform/themes/blank/templates/FormStepsControl.vue';
import FormStep from '@vueform/vueform/themes/blank/templates/FormStep.vue';
import FormElements from '@vueform/vueform/themes/blank/templates/FormElements.vue';
import ElementLayout from '@vueform/vueform/themes/blank/templates/ElementLayout.vue';
import ElementLayoutInline from '@vueform/vueform/themes/blank/templates/ElementLayoutInline.vue';
import ElementLoader from '@vueform/vueform/themes/blank/templates/ElementLoader.vue';
import ElementLabelFloating from '@vueform/vueform/themes/blank/templates/ElementLabelFloating.vue';
import ElementLabel from '@vueform/vueform/themes/blank/templates/ElementLabel.vue';
import ElementInfo from '@vueform/vueform/themes/blank/templates/ElementInfo.vue';
import ElementDescription from '@vueform/vueform/themes/blank/templates/ElementDescription.vue';
import ElementError from '@vueform/vueform/themes/blank/templates/ElementError.vue';
import ElementMessage from '@vueform/vueform/themes/blank/templates/ElementMessage.vue';
import ElementText from '@vueform/vueform/themes/blank/templates/ElementText.vue';
import ElementAddon from '@vueform/vueform/themes/blank/templates/ElementAddon.vue';
import ElementAddonOptions from '@vueform/vueform/themes/blank/templates/ElementAddonOptions.vue';

import CaptchaElement from '@vueform/vueform/themes/blank/templates/elements/CaptchaElement.vue';
import CheckboxElement from '@vueform/vueform/themes/blank/templates/elements/CheckboxElement.vue';
import SelectElement from '@vueform/vueform/themes/blank/templates/elements/SelectElement.vue';
import TextElement from '@vueform/vueform/themes/blank/templates/elements/TextElement.vue';

import columns from '@vueform/vueform/themes/tailwind/columns';
import classes from '@vueform/vueform/themes/tailwind/classes';

export const theme = {
  classes,
  columns,
  templates: {
    Vueform,
    FormElements,
    // Essential form components (optional)
    FormErrors,
    FormMessages,
    // Essential element components (optional)
    ElementLayout,
    ElementLayoutInline,
    ElementLoader,
    ElementLabelFloating,
    ElementLabel,
    ElementInfo,
    ElementDescription,
    ElementError,
    ElementMessage,
    ElementText,
    ElementAddon,
    ElementAddonOptions,
    // Optional form components
    FormSteps,
    FormStepsControls,
    FormStepsControl,
    FormStep,
    // Elements
    CheckboxElement, // this one
    SelectElement, // this one
    TextElement, // this one
    CaptchaElement,
  },
};
