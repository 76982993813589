<script>
import { defineElement } from '@vueform/vueform/src/core.js';
export default defineElement({
  name: 'VueformPasswordElement',
  props: {
    displayFeedback: {
      type: Boolean,
      required: false,
      default: true,
    },
    labelValue: {
      type: String,
      required: false,
      default: 'password',
    },
    placeholderValue: {
      type: String,
      required: false,
      default: 'placeholder.password',
    },
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <DesignSystemFormPassword
        v-model="value"
        :label="labelValue"
        class="w-full"
        :feedback="displayFeedback"
        :placeholder="placeholderValue"
      />
    </template>
  </ElementLayout>
</template>
