import {
  FormErrors,
  FormMessages,
  ElementLayout,
  ElementLayoutInline,
  ElementLoader,
  ElementLabelFloating,
  ElementLabel,
  ElementInfo,
  ElementDescription,
  ElementError,
  ElementMessage,
  ElementText,
  ElementAddon,
  ElementAddonOptions,
  ElementRequired,
  FormSteps,
  FormStepsControls,
  FormStepsControl,
  FormStep,
  CheckboxElement,
  SelectElement,
  TextElement,
  CaptchaElement,
} from '@vueform/vueform/src/core.js';

export const components = {
  FormErrors,
  FormMessages,
  // Essential element components
  ElementLayout,
  ElementLayoutInline,
  ElementLoader,
  ElementLabelFloating,
  ElementLabel,
  ElementInfo,
  ElementDescription,
  ElementError,
  ElementMessage,
  ElementText,
  ElementAddon,
  ElementAddonOptions,
  ElementRequired,
  // Optional form components
  FormSteps,
  FormStepsControls,
  FormStepsControl,
  FormStep,
  // Elements
  CheckboxElement, // this one
  SelectElement, // this one
  TextElement, // this one
  CaptchaElement,
};
