import en from '@vueform/vueform/locales/en';
import {
  defineConfig,
  regex,
  required,
  confirmed,
  captcha,
} from '@vueform/vueform/src/core.js';

import { components } from './vueform.components';
import { theme } from './vueform.theme';
import { elements } from './vueform.elements';

export default defineConfig({
  elements,
  theme,
  locales: { en },
  locale: 'en',
  components,
  rules: { regex, required, confirmed, captcha },
  endpoints: {},
});
