import Vueform, { Validator } from '@vueform/vueform/src/core.js';
import vueformConfig from '~/vueform.config';
import dayjs from 'dayjs';

export default defineNuxtPlugin({
  dependsOn: ['init-client', 'locale-service'],
  parallel: true,
  setup(nuxt) {
    nuxt.vueApp.use(Vueform, vueformConfig);

    const siteStore = useSiteStore();
    const { $t } = useNuxtApp();

    const mobileNumberRegex = computed(() => {
      return `regex:/^(${siteStore.getDialingCode?.substring(
        1
      )}|0)?[1-9][0-9]{8}$/g`;
    });
    const underageCheck = class extends Validator {
      get msg() {
        return $t('formErrors.dateOfBirth-underage');
      }

      check(value) {
        const date = dayjs();
        const difference = date.diff(value, 'year');
        return !(difference < 18);
      }
    };

    return {
      provide: {
        underageCheck,
        mobileNumberRegex: mobileNumberRegex.value,
      },
    };
  },
});
