<script setup lang="ts">
import type { InputProps } from '~/interfaces/form/textInputInterface';
import InputText from 'primevue/inputtext';
const props = withDefaults(defineProps<InputProps>(), {
  class: '',
  prefixCopy: '',
  suffixCopy: '',
  prefixPath: '',
  suffixPath: '',
  formKey: '',
  label: '',
  type: 'text',
  modelValue: '',
  required: false,
  hidden: false,
  placeholder: '',
  disabled: false,
});

const emit = defineEmits(['update:modelValue', 'blur']);
</script>

<template>
  <span class="p-float-label w-full">
    <InputText
      :id="props.formKey"
      ref="input"
      autocomplete="off"
      :class="props.class"
      :type="props.type"
      :required="props.required"
      :model-value="modelValue"
      :readonly="false"
      :hidden="props.hidden"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="$emit('blur')"
    />
    <label class="input-label" :for="props.label">{{ props.label }}</label>
  </span>
</template>

<style>
.p-component:disabled {
  opacity: 1;
}
</style>
